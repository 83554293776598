import React from 'react'
import Banners from './Banners'
import PageContent from './PageContent';
import Categories from './Categories';
import Brands from './Brands';
import BrandsWithCategory from './BrandsWithCategory';
import ContactForm from './ContactForm';
import Helmet from 'react-helmet';

const Pages = ({slug, pages, categories, brands, products}) => {
  const page = pages.filter(function (e) {
    return e.slug === slug;
  });

  const capitalizeFirst = str => {
    return str.charAt(0).toUpperCase() + str.slice(1);
  };

    // Fallback values for title and description
    const defaultTitle = "PSM Vision | UK’s Leading Wholesaler in Health, Beauty, Skincare & Cosmetics Products";
    const defaultDescription = "PSM Vision is the UK's leading cosmetics wholesaler, offering a wide range of health, beauty, skincare, and cosmetics products, including medical devices, fragrances, toiletries, and more.";
    const defaultMetaDescription = defaultDescription;
    console.log('page ', page)
    // Dynamic values from page if available, else fallback to default
    const pageTitle = page && page[0] && page[0].title ? page[0].title : defaultTitle;
    const pageDescription = page && page[0] && page[0].description ? page[0].description : defaultDescription;
    const pageMetaDescription = page && page[0] && page[0].metaDescription ? page[0].metaDescription : defaultMetaDescription;
  
  
  return (
    <>
      {/* <Helmet>
        <title>{capitalizeFirst(pageTitle)}</title>
        <meta name="description" content={pageDescription} />
        <meta name="keywords" content="psm, psmvision, psmvision limited, psm limited, wholesale, retail, import, export, import export, wholesale price, retail price, best price, amazon retail, amazon wholesale, amazon online, health and beauty, health, beauty, skin, skin care, hair, hair care, mother care, baby care, mother and baby care, medical device, health care, fragrances, toiletries, bioderma, clarins, decleor aroma, institut esthederm, lancaster, moroccanoil, neutrogena, olay, shiseido, st ives, vaseline, ccs, e45, avene, cetaphil, okeeffes, fanola, fudge, loreal, olaplex, revlon, schwarzkopf, shea moisture, tigi, wella, milk shake, aveeno, avent, danone, dr bronners, huggies, johnsons baby, lansinoh, nuby, nuk, pampers, sambucol, vital baby, barry m, bourjois, carmex, clinique, eylure, mac, max factor, maybelline, miss sporty, nip fab, nuxe, nyx, opi, orly, rimmel, sally hansen, duo, oh my lash, better you, centrum, clearblue, dentek, durex, haliborange, bausch lomb, himalaya, holland barrett, listerine, natures aid, oral b, solgar, tepe, valupak, first response, health aid, kalms, seven seas, vita biotics, vizulize, optrex, profoot, scholl, nelson, armani, beckham, bruno banani, calvin klein, davidoff, dior, elizabeth arden, estee lauder, givenchy, gucci, hugo boss, james bond, jo malone, jovan, lacoste, mexx, mont blanc, tom ford, vera wang, euthymol, coty laimant, american crew, brylcreem, dove, gillette, nivea, tena, toni guy, aussie care, simple, gorilla, palmers, veet" />
        <meta name="description" content={pageMetaDescription} />
      </Helmet> */}
      {page ?
      <>
        {page[0].banners.length > 0 
        ? <Banners banners={page} brands={brands} products={products} /> 
        : 
          <section className={`psm-section page-banner`}>  
            <div className="container">                    
              <h2>{page[0].title}</h2>       
            </div>
          </section>        
        } 

        {page[0].content
        ? <PageContent page={page}/> 
        : null}      
        
        {slug === 'brands'
        ? <BrandsWithCategory brands={brands} categories={categories}/>
        : null}

        {slug === 'contact'
        ? <ContactForm brands={brands} categories={categories} />
        : null}        
        
        {slug === 'home'
        ? <Categories categories={categories}/>
        : null}

        {slug === 'home'
        ? <Brands brands={brands}/>
        : null}      
      </>
      : <h1>No pages</h1> }
    </>
  )
}

export default Pages
