import React, {useState, useMemo} from 'react'
import { useParams } from "react-router-dom";
import parse from 'html-react-parser'
import Banners from './Banners'
import { Link } from "react-router-dom"
import Helmet from 'react-helmet';
import Pagination from './Pagination';

let PageSize = 25;

const brandMetadata = {
  avene: {
    title: "Get Avene Skincare in Bulk – Hydrating, Premium Quality",
    description: "Order Avene Skincare Products Online – Top Quality Skincare for All Skin Types & Concerns | Gentle, Hydrating, Dermatologist-Recommended"
  },
  bioderma: {
    title: "Buy Bioderma Skincare Set in Bulk – Trusted Skincare Solutions",
    description: "Buy Bioderma Skincare Set in Bulk – Affordable, High-Quality Skincare Solutions for All Skin Types | PSM Vision, Trusted Skincare Experts"
  },
  ccs: {
    title: "Bulk CCS Skincare Products – Best Skincare for All Skin Types",
    description: "Bulk CCS Skincare Products Available – Best Skincare for All Skin Types | Affordable, High-Quality Skincare Solutions | PSM Vision"
  },
  cetaphil: {
    title: "Cetaphil Bulk Skincare – Trusted, Gentle Solutions",
    description: "Shop Cetaphil Skincare in Bulk – Gentle, Effective Solutions for Sensitive Skin | Trusted, Dermatologist-Recommended Products at PSM Vision"
  },
  clarins: {
    title: "Get Clarins Skincare Wholesale – Luxury, Anti-Aging Care",
    description: "Get Clarins Skincare Wholesale – Luxury, High-Quality Skincare for All Skin Types | Anti-Aging & Hydrating Products at PSM Vision"
  },
  decleor: {
    title: "Explore Decleor Paris Wholesale Skincare – Natura Care",
    description: "Explore Decleor Paris Wholesale Skincare – Bulk Pricing on High-Quality, Natural Skincare Products for All Skin Types at PSM Vision"
  },
  e45: {
    title: "Explore E45 Skincare Routine – Gentle, Moisturizing Bulk Orders",
    description: "Explore E45 Skincare Routine – Bulk Orders of Gentle, Moisturizing Products at PSM Vision | Skincare Wholesaler"
  },
  eucerin: {
    title: "Discover Eucerin Skincare – Bulk Orders of High-Quality Products",
    description: "Discover the Best of Eucerin Skincare – Bulk Orders of High-Quality, Dermatologist-Recommended Products for Retailers"
  },
  institutEsthederm: {
    title: "Institut Esthederm Skincare – Bulk Orders of Anti-Aging Products",
    description: "Stock Up on Institut Esthederm Skincare – Wholesale Bulk Orders of High-Quality, Anti-Aging Skincare Products for All Skin Types"
  },
  lancaster: {
    title: "Lancaster Skincare Bulk Orders – Anti-Aging, Hydrating Products",
    description: "Lancaster Skincare Bulk Orders | Top-Quality Anti-Aging, Hydrating Skincare Products for Retailers | Trusted Wholesale Supplier"
  },
  moroccanoil: {
    title: "Order Moroccanoil Skincare in Bulk – Hydrating Skincare",
    description: "Order Moroccanoil Skincare in Bulk – Wholesale Solutions for High-Quality, Hydrating, and Nourishing Skincare Products"
  },
  neutrogena: {
    title: "Shop Neutrogena Skincare in Bulk – Reliable Skincare Products",
    description: "Shop Neutrogena Skincare in Bulk – Place Wholesale Orders for High-Quality, Dermatologist-Recommended Skincare Products"
  },
  okeeffes: {
    title: "O'Keeffe's Skincare Bulk – High-Quality Moisturizing Products",
    description: "Get the Best O'Keeffe's Skincare in Bulk – High-Quality, Moisturizing Skincare Products for Retailers | Trusted Wholesale Supplier"
  },
  olay: {
    title: "Bulk Olay Skincare – High-Quality Anti-Aging, Hydrating Products",
    description: "Affordable Bulk Olay Skincare – High-Quality, Anti-Aging & Hydrating Products Perfect for Retail Stores | Trusted Wholesale Deals"
  },
  shiseido: {
    title: "Buy Shiseido Skincare in Bulk – Best Prices on Anti-Aging Products",
    description: "Buy Shiseido Skincare in Bulk – Best Prices Guaranteed on High-Quality, Anti-Aging & Hydrating Skincare Products"
  },
  stIves: {
    title: "Wholesale St. Ives Skincare – Natural, Nourishing Bulk Options",
    description: "Wholesale St. Ives Skincare – Bulk Options for Retail Stores | Affordable, Natural, and Nourishing Skincare Products"
  },
  vaseline: {
    title: "Bulk Vaseline Skincare – High-Quality Moisturizing Products",
    description: "Bulk Vaseline Skincare at Special Wholesale Rates – High-Quality Moisturizing & Healing Skincare Products for Retailers"
  }
};

const Products = ({slug, pages, products, brands}) => {

  const [currentPage, setCurrentPage] = useState(1);
  const location = useParams();
  const page = pages.filter(function (e) {
    return e.slug === slug;
  });

  const brandID = brands.filter(function(e, i){
    return parse(e.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  ) === location.brandName;
  });

  const filterproductsByBrand = products.filter(function (e) {    
    return e.brand_id === brandID[0].id;
  });
  

  const currentTableData = useMemo(() => {
    const firstPageIndex = (currentPage - 1) * PageSize;
    const lastPageIndex = firstPageIndex + PageSize;
    return filterproductsByBrand.slice(firstPageIndex, lastPageIndex);
  }, [currentPage]);  

  function makeTitle(slug) {
    var words = slug.split('-');
    for (var i = 0; i < words.length; i++) {
      var word = words[i];
      words[i] = word.charAt(0).toUpperCase() + word.slice(1);
    }
    return words.join(' ');
  }
  console.log('location ', makeTitle(location.brandName))

  const brandName = location.brandName.toLowerCase();
  const metadata = brandMetadata[brandName] || {
    title: `Shop ${makeTitle(location.brandName)} Products in Bulk – Best Quality and Prices`,
    description: `Browse our wide range of ${makeTitle(location.brandName)} products in bulk at the best prices. High-quality and affordable skincare solutions for all skin types.`
  };


  return (
    <>
  <Helmet>
        <title>{metadata.title}</title>
        <meta name="description" content={metadata.description} />
        <meta name="keywords" content={makeTitle(location.brandName)} />
      </Helmet>
    <Banners banners={page} brand_name={location.brandName} brands={brands} products={products} /> 
    <section className="psm-section psm-products">
      <div className="container">
        {currentTableData.map((product, index) => {
          return (
            <React.Fragment key={index}>
              
                <div className="product">
                  <h2><Link to={`/product/${parse(product.title.toString().normalize('NFKD').toLowerCase().trim().replace(/\s+/g, '-').replace(/[^\w\-]+/g, '').replace(/\-\-+/g, '-')  )}`}>{parse(product.title)}</Link></h2><br />
                  <h3>EAN: {parse(product.content)}</h3>
                </div>
              
            </React.Fragment>
          );
        })}
          <Pagination
            className="pagination-bar"
            currentPage={currentPage}
            totalCount={filterproductsByBrand.length}
            pageSize={PageSize}
            onPageChange={page => setCurrentPage(page)}
          />        
      </div>
    </section>
    </>
  )
}

export default Products